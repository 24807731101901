<template>
  <div class="user">
    <Bscroll
      class="scroll"
      ref="scroll"
    >
      <!-- 登录头像 -->
      <div class="user-login">
        <h4 class="title">我的</h4>
        <div
          class="user-card"
          @click="binding()"
        >
          <div class="user-top">
            <span class="large">{{$store.state.nameState}}
              <img
                v-if="$store.state.cardList.length > 0 ? true : false"
                class="badge"
                src="../../static/img/user/huizhang.webp"
                alt=""
              >
            </span>
            <img
              v-if="$store.state.cardList.length > 0 ? true : false"
              :src="$store.state.headPortrait"
            >
            <div v-else>
              <svg
                style="font-size: 3rem;position: relative;z-index: 1;color:#fff"
                class="icon"
                aria-hidden="true"
              >
                <use xlink:href='#icon-zhuce1'></use>
              </svg>
            </div>
          </div>
          <div
            class="user-bottom"
            v-if="$store.state.cardList.length == 0"
          >
            <h5 class="in-size">绑卡操作</h5>
            <span
              class="small-six-size"
              style="color:rgb(153, 150, 150)"
            >您还没有绑定院内卡,先来绑卡操作吧!</span>
          </div>
          <div
            class="user-bottom"
            v-else
          >
            <h5 class="in-size"> </h5>
            <span style="color:rgb(153, 150, 150);line-height:5rem">生活因健康而美丽</span>
          </div>
        </div>
      </div>
      <!-- 信息列表 -->
      <div class="user-list">
        <UserList
          ref="user"
          class="UserList-a"
        />
      </div>
    </Bscroll>
  </div>
</template>

<script>
import Bscroll from '@/components/content/scroll/BScroll'
import UserList from '@/components/common/userlist/UserList'
export default {
  components: {
    UserList,
    Bscroll
  },
  mounted () {
    console.log(this.$store.state.cardList)
    this.$store.commit('USER_POST')
    setTimeout(() => {
      this.$refs.scroll.refresh()
    }, 300)
  },
  methods: {
    binding () {
      this.$refs.user.queryClick({ item: 'user-data' })
    },
  },
}
</script>

<style lang="less" scoped>
.user {
  margin-bottom: 4rem;
  .scroll {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    overflow: hidden;
    height: calc(100% - 3.75rem);
    .user-hd {
      h3 {
        text-align: center;
        line-height: 3rem;
        color: #ffffff;
        background-color: @darkColor;
      }
    }
    .user-login {
      background: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0) 40%,
          rgb(255, 255, 255, 0) 60%
        ),
        url("../../static/img/user/beijing.webp");
      background-size: 100% 15rem;
      background-repeat: no-repeat;
      height: 10rem;
      .title {
        text-align: center;
        padding-top: 1.3rem;
        color: #ffffff;
        font-weight: 400;
      }
      .user-card {
        position: relative;
        top: 1.5rem;
        margin: auto;
        height: 10rem;
        width: 95vw;
        border-radius: 0.5rem;
        background-color: rgb(255, 248, 239);
        background-image: url("../../static/img/user/kuai1.webp");
        background-size: 100% 100%;
        background-repeat: no-repeat;
        box-shadow: 0px 0px 8px 5px rgba(0, 0, 0, 0.1);
        .user-top {
          display: flex;
          align-items: center;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 1rem;
          .badge {
            width: 1.5rem;
            margin-left: 0.5rem;
          }
          img {
            border-radius: 50%;
            width: 2.5rem;
          }
        }
        .user-bottom {
          padding-left: 1rem;
          padding-top: 1rem;
          line-height: 1.3rem;
        }
      }
    }
  }
  .user-list {
    margin-top: 5.3rem;
  }
}
</style>