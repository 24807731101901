<template>
  <!-- 个人中心列表组件 -->
  <div class="user-list">
    <div
      class="user-lt"
      v-for="(item,index) in list"
      :key="index"
      @click="queryClick(item)"
    >
      <div>
        <svg
          class="icon"
          aria-hidden="true"
        >
          <use
            class="icon-u"
            :xlink:href='item.icon'
          ></use>
        </svg>
      </div>
      <span
        style="margin-left:1rem"
        class="in-size"
      >{{item.name}}</span>
      <span>
        <svg
          class="icon"
          aria-hidden="true"
          style="font-size:1.5rem"
        >
          <use xlink:href='#icon-youjiantou1-copy'></use>
        </svg>
      </span>
    </div>
  </div>
</template>

<script>
import { saveSerialNumberPost } from '@/network/service'
export default {
  data () {
    return {
      list: [{
        name: '我的就诊人',
        icon: '#icon-jiuzhenrenguanli-fuyou-copy',
        path: '/user-data'
      },
      // {
      //   name: '预约记录',
      //   icon: '#icon-yuyueguahao',
      //   path: '/appointment-record'
      // },
      {
        name: '挂号记录',
        icon: '#icon-dangriguahao-fuyou-copy',
        path: '/registration-record'
      },
      {
        name: '支付订单查询',
        icon: '#icon-mingxi-fuyou-copy',
        path: '/payment-record-query'
      }]
    }
  },
  methods: {
    async queryClick (item) {
      if (this.$store.state.cardList.length == 0) {
        this.$router.push('/login');
      } else {
        if (item.path == '/registration-record') {
          let res = await saveSerialNumberPost({
            name: this.$store.state.cardList[0].name,
            idno: this.$store.state.cardList[0].idNo,
            card_no: this.$store.state.cardList[0].cardNo,
            card_type: this.$store.state.cardList[0].cardType,
            menuName: '挂号查询'
          })
          this.$store.state.seq = res.data.processSeq
          this.$router.push(item.path)
        } else {
          this.$router.push(item.path)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.user-list {
  background-color: #ffffff;
  .user-lt {
    display: flex;
    align-items: center;
    margin: auto;
    height: 3.75rem;
    width: 95vw;
    margin-bottom: 1rem;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    padding: 0 1rem;
    box-sizing: border-box;
    .icon {
      margin-right: 0.5rem;
    }
    span:last-child {
      position: absolute;
      right: 1rem;
    }
  }
}
</style>